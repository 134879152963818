import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzDifferentlyStyledValueComponent } from './kurz-differently-styled-value.component';
import { ActivateElementModule } from '@util/directives/activate-element/activate-tab-element.module';


@NgModule({
  declarations: [
    KurzDifferentlyStyledValueComponent
  ],
  imports: [
    CommonModule,
    ActivateElementModule
  ],
  exports: [KurzDifferentlyStyledValueComponent]
})
export class KurzDifferentlyStyledValueModule { }
